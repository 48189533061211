<template>
    <div class="auth-kyc">
        <div class="load load-white load-200" v-if="!showContent"></div>

        <div class="container" v-if="showContent && errorsGet.dataKYC != false">
            <div class="box-error">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", errorsGet.dataKYC) }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataKYC}}
                </div>
            </div>
        </div>

        <!-- IF SUMSUB -->
        <div class="container" v-if="showContent && KycSumSub && !errorsGet.dataKYC">            
            <div id="sumsub-websdk-container"></div>
        </div>

        <!-- IF SHUFTI -->
        <div class="container" v-if="showContent && kycShuftiPro && !errorsGet.dataKYC">
            <iframe :src='dataKYC.accessToken' allow="camera" id="Shuftipro-iframe" frameborder="0" class="ShuftiproIframe"></iframe>
        </div>
    </div>
</template>

<script>
    import snsWebSdk from '@sumsub/websdk';
    import apiInternational from '@/config/apiInternational.js'

    export default {
        data(){
            return{
                showContent: false,
                KycSumSub: false,
                kycShuftiPro: false,

                dataKYC: {},

                errorsGet: {
                    dataKYC: false
                },

                // flagSelected: "en",
            }
        },

        async mounted(){
            setTimeout(() => {
                this.authKYC()
            }, 2500);

            await apiInternational.get('/api/v1/platform/account/international/kyc')
            .then(response => {
                this.dataKYC = response.data
                this.modalKYC = true

                if ( this.dataKYC.provider === "ShuftiPro" ){
                    this.kycShuftiPro = true
                } else{
                    this.KycSumSub = true
                }
                
                this.showContent = true
            })
            .catch(error => {
                this.errorsGet.dataKYC = error.response.status
                this.errorsGet.dataKYC = true
                this.showContent = true
            })
            
            this.launchWebSdk(this.dataKYC.accessToken, this.dataKYC.applicantEmail, this.dataKYC.applicantPhoneNumber, this.dataKYC.language)
        },

        methods: {
            launchWebSdk(accessToken, applicantEmail, applicantPhone, applicantLanguage) {
                let snsWebSdkInstance = snsWebSdk
                    .init(accessToken, () => this.getNewAccessToken())
                    .withConf({
                        lang: applicantLanguage,
                        email: applicantEmail,
                        phone: applicantPhone,
                    })
                    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                    .on('idCheck.onStepCompleted', (payload) => {
                        console.log('onStepCompleted', payload)
                    })
                    .on('idCheck.onError', (error) => {
                        console.log('onError', error)
                    })
                    .onMessage((type, payload) => {
                        console.log("onMessage", type, payload);
                    })
                    .on('idCheck.onApplicantStatusChanged', (status) => {
                        if ( status.reviewResult.reviewAnswer === "RED" && status.reviewResult.reviewRejectType === "RETRY" ){
                            this.$emit('statusChange', 'retry');
                        }

                        if ( status.reviewResult.reviewAnswer === "RED" && status.reviewResult.reviewRejectType === "FINAL" ){
                            // window.location.href = `/${this.flagSelected}/account/pending`
                            this.$emit('statusChange', 'pending');
                        }

                        if ( status.reviewResult.reviewAnswer === "GREEN" ){
                            // window.location.href = `/${this.flagSelected}/account/compliance`
                            this.$emit('statusChange', 'success');
                        }
                    })

                    .build();

                snsWebSdkInstance.launch('#sumsub-websdk-container')
            },

            getNewAccessToken() {
                return Promise.resolve(this.dataKYC.accessToken)
            },





            authKYC(){
                this.$emit('statusChange', 'success');
            }
        }
    }
</script>