export function handleError(error, language, accountType) {
    switch (error.response.status) {
        case 401:
            localStorage.removeItem('accessToken');
            window.location.href = `/${language}/account/login`
            break;

        case 403:
            window.location.href = `/${language}/account/consent`
            break;

        case 409:
            if (error.response.data === "account_must_have_kyc") {
                window.location.href = `/${language}/account/kyc`
            }

            if (error.response.data === "account_must_have_compliance") {
                window.location.href = `/${language}/account/compliance`
            }

            if (error.response.data === "account_must_have_company_compliance") {
                window.location.href = `/${language}/account/compliance-company`
            }

            if (error.response.data === "account_already_have_wallet_stake") {
                window.location.href = `/${language}/client-space/purchase`
                // this.$router.push({ name: 'ClientSpacePurchase', params: { lang: this.$route.params.lang } })
            }

            if (error.response.data === "account_must_be_manualy_checked") {
                window.location.href = `/${language}/account/pending`
            }

            if (error.response.data === "account_must_have_beneficial_owner" && accountType == "Person") {
                window.location.href = `/${language}/account/beneficial-owner`
                // this.$router.push({ name: 'BeneficialOwner', params: { lang: this.$route.params.lang } })
            }

            if (error.response.data === "account_must_have_company_beneficial_owner" && accountType == "Company") {
                window.location.href = `/${language}/account/controlling-person-legal-entities`
                // this.$router.push({ name: 'ControllingPersonLegalEntities', params: { lang: this.$route.params.lang } })
            }

            if (error.response.data === "wrong_account_type") {
                this.blockPurchase = true
            }

            if (error.response.data === "account_must_have_wallet_stake") {
                window.location.href = `/${language}/client-space/wallet-stake`
                // this.$router.push({ name: 'ClientSpaceWalletStake', params: { lang: this.$route.params.lang } })
            }
            break;

        default:
            console.log(`Sorry, we are out of ${error.response.status}.`);
    }
}