<template>
    <div class="home beneficial-page">
        <div id="dne"></div>
        <Header />

        <section class="beneficial">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataAccount != false">
                <div class="box-error">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>

                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataAccount}}
                    </div>
                </div>
            </div>

            <div class="container" v-if="showContent && !errorsGet.dataAccount">
                
                <div class="box-beneficial">
                    <h2 class="title-secondary">Establishing of the controlling person of operating legal entities and partnerships both not quoted on the stock exchange</h2>
                    
                    <!-- FORM CONTROLLING -->
                    <div v-if="auth2fa.status == 'form'">
                        <div class="box-registration">
                            <div class="formBox">
                                <p>(for operating legal entities and partnerships that are contracting partner as well as analogously for operating legal entities and partnerships that are beneficial owners)</p>
                                <div class="partner">
                                    <div>
                                        <h3 class="subtitle">Contracting partner:</h3>
                                        <p>DNE International Sagl</p>
                                        <p>CHE-409.547.100 / Switzerland</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="formBox">
                                <h3>Pursuant to the anti-money laundering legislation, the contracting partner(s) hereby declare(s) (tick the appropriate box):</h3>

                                <div class="form-group-radio">
                                    <input type="radio" id="box1" v-model="signature.legislation" value="Legislation 01" name="radioBoxLegislation" class="form-radio" @click='signature.legislation = "Legislation 01"' :checked='signature.legislation == "Legislation 01"' >
                                    <label for="box1" class="form-radio-label">
                                        <span>the person(s) listed below is / are holding 25 % or more of the contracting partner’s shares (capital shares or voting rights); or</span>
                                    </label>

                                    <input type="radio" id="box2" v-model="signature.legislation" value="Legislation 02" name="radioBoxLegislation" class="form-radio" @click='signature.legislation = "Legislation 02"' :checked='signature.legislation == "Legislation 02"' >
                                    <label for="box2" class="form-radio-label">
                                        <span>if there are no capital shares or voting rights of 25 % or more, that the following person(s) listed below is / are controlling the contracting partner in other ways; or</span>
                                    </label>
                                    
                                    <input type="radio" id="box3" v-model="signature.legislation" value="Legislation 03" name="radioBoxLegislation" class="form-radio" @click='signature.legislation = "Legislation 03"' :checked='signature.legislation == "Legislation 03"' >
                                    <label for="box3" class="form-radio-label last">
                                        <span>in case no person(s) exist(s) who exercise(s) control over the contracting partner in a different capacity, the contracting partner hereby declares that the person(s) listed below is / are the managing director(s).</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="anc" id="error"></div>

                            <div class="formBox">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="formName">
                                            <span>First name(s), last name(s) / entity:</span>
                                            <input type="text" class="form" :class="{error: errorsForm.fullName}" v-model="signature.fullName" v-on:input="signatureName()" @keyup='validateError("fullName")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'>
                                            <small class="error" v-if="errorsForm.fullName">{{getMyLanguage("compliance","compliance.error.comparative")}} {{dataAccount.company.fullName}}</small>
                                        </label>
                                    </div>

                                    <div class="col-12">
                                        <label class="formAddress last">
                                            <span>Actual address of domicile / registered office (incl. country):</span>
                                            <input type="text" class="form" :class="{error: errorsForm.fullAddress}" v-model="signature.fullAddress" @keyup='validateError("fullAddress")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullAddress")'>
                                            <small class="error" v-if="errorsForm.fullAddress">{{getMyLanguage("compliance","compliance.error.address-empty")}}</small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="formBox">
                                <h3>Fiduciary holding of assets</h3>
                                <p>Is a third person the beneficial owner of the assets booked under the above relationship?</p>
                                <div class="form-group-radio">
                                    <input type="radio" id="boxNo" v-model="signature.fiduciary" value="No" name="radioBoxRelationship" class="form-radio" @click='signature.fiduciary = "No"' :checked='signature.fiduciary == "No"' >
                                    <label for="boxNo" class="form-radio-label">
                                        <span>No.</span>
                                    </label>

                                    <input type="radio" id="boxYes" v-model="signature.fiduciary" value="Yes" name="radioBoxRelationship" class="form-radio" @click='signature.fiduciary = "Yes"' :checked='signature.fiduciary == "Yes"' >
                                    <label for="boxYes" class="form-radio-label last">
                                        <span>Yes. &rarr; The relevant information regarding the beneficial owner has to be obtained by filling in a separate form A.</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="box-registration">
                            <div class="formBox">
                                <h3 class="subtitle">The contracting partner(s) hereby undertake(s) to automatically inform the bank of any changes.</h3>

                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Date:</span>
                                            <span class="form formDateToday">{{dateTime()}}</span>
                                        </label>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label>
                                            <span>Signature(s):</span>

                                            <div class="digital-signature" :class="{error: errorsForm.signature}">
                                                <vueSignature
                                                ref="signature"
                                                :sigOption="digitalSignature.option"
                                                :w="'100%'"
                                                :h="'120px'"
                                                ></vueSignature>

                                                <span @click="clear" class="btn-clear"></span>
                                                
                                                <label>
                                                    <small class="error" v-if="errorsForm.signature">
                                                        {{getMyLanguage("compliance","compliance.error.signature-empty")}}
                                                    </small>
                                                </label>
                                            </div>

                                            <span class="form formSignature" v-html="signature.fullName"></span>
                                        </label>
                                    </div>
                                </div>

                                <p class="alert">It is a criminal offence to deliberately provide false information on this form (Article 251 of the Swiss Criminal Code, document forgery).</p>
                            </div>
                        </div>

                        <div class="bt-registration">
                            <!-- <div class="btn-primary" @click='validateForm()'>{{ getMyLanguage("client-space", "instructions.receipt.send") }}</div> -->
                            <div class="btn-primary" @click='validateForm()'>{{ getMyLanguage("client-space", "instructions.receipt.send") }}</div>
                        </div>
                    </div>


                    <!-- AUTH 2FA -->
                    <AuthKYC @statusChange="handleStatusChange" :dataSource="auth2fa.data" v-if="auth2fa.status == 'auth'" />

                    <!-- SUCCESS 2FA -->
                    <div v-if="auth2fa.status == 'success'">
                        <div class="box-registration box-status-2fa success-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">Sucesso</h2>
                                <p>Sua identificação foi confirmada com sucesso!</p>
                                <div class="redirecting">Redirecionando...</div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- RETRY 2FA -->
                    <div v-if="auth2fa.status == 'retry'">
                        <div class="box-registration box-status-2fa retry-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">Houve uma falha</h2>
                                <p>Precisamos repetir o processo de identificação.</p>

                                <div class="bts">
                                    <div class="btn-primary" @click="auth2fa.status = 'auth'">Repetir</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- PENDENTE 2FA -->
                    <div v-if="auth2fa.status == 'pending'">
                        <div class="box-registration box-status-2fa pending-2fa">
                            <div class="formBox">
                                <h2 class="subtitle">Pendente</h2>
                                <p>Por favor aguarde enquanto verificamos sua identificação.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- MODAL 2FA -->
            <div class="modal modal-2fa" v-if="modalSMS.show">
                <div class="modal-cont">
                    <div class="modal-close" @click="modalSMS.show = false"></div>
                    <div class="box">
                        <div class="modal-scroll">

                            <!-- SMS CODE -->
                            <div class="sms-code" v-if="!flowSMS.success">
                                <h3 class="title-secondary">{{getMyLanguage("compliance","compliance.modal2fa.title")}}</h3>
                                <div class="load" v-if="flowSMS.load"></div>

                                <p v-if="!flowSMS.load">{{getMyLanguage("compliance","compliance.modal2fa.inf")}}</p>
                                <div class="sms-code-cont" v-if="!flowSMS.load">
                                    <!-- <div class="sms-confirm" v-if="flowSMS.smsCodeShow && !flowSMS.load"> -->
                                    <div class="sms-confirm">
                                        <input type="text" class="form form-smsCode" v-model="codeSMS.smsCode" v-mask="'000000'" placeholder="______" autocomplete="one-time-code">
                                        <div class="confirm btn-disable" v-if="codeSMS.smsCode.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                        <div class="confirm" v-if="codeSMS.smsCode.length === 6" @click="validateCodeSMS()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                    </div>
                                    
                                    <div class="alert" v-if="flowSMS.smsCodeShow && !flowSMS.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_country_ddi}} {{$store.state.profile.profile_phone_number}}</div>
                                    <div class="load" v-if="flowSMS.smsCodeShow && flowSMS.load"></div>
                                </div>

                                <div class="resend" v-if="flowSMS.smsCodeShow && !flowSMS.load">
                                    <div class="btn-resend resend-load" v-if="!flowSMS.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSMS.countDownResendCode}}s</div>
                                    <div class="btn-resend" v-if="flowSMS.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                                </div>
                            </div>

                            <!-- SUCCESS -->
                            <div class="success" v-if="flowSMS.success">
                                <h3 class="title-secondary">{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.title")}}</h3>
                                
                                <div class="box-success">
                                    <p>{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.msg")}}</p>
                                    <a :href="`/${flagSelected}/client-space/purchase`" class="btn-primary">{{getMyLanguage("compliance","compliance.cta-success")}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiInternational from '@/config/apiInternational.js'
    // import apiPlatform from '@/config/apiPlatform.js'
    import moment from 'moment';

    import vueSignature from "vue-signature";
    import AuthKYC from "@/components/AuthKYC.vue"

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header,
            vueSignature,
            AuthKYC
        },

        data(){
            return{
                showContent: false,
                flagSelected: "en",
                load: false,

                signature: {
                    legislation: "Legislation 01",
                    fullName: "",
                    fullAddress: "",
                    fiduciary: "No",
                    smsCode: 0,
                    signature: ""
                },

                digitalSignature: {
                    option: {
                        penColor: "rgb(0, 0, 0)",
                        backgroundColor: "rgb(255,255,255)",
                    },
                },

                errorsForm: {
                    fullName: false,
                    fullAddress: false,
                    signature: false
                },

                errorsGet: {
                    dataAccount: false
                },

                errorsPost: {
                    signature: false
                },
                
                dataAccount: {},

                auth2fa: {
                    status: "auth",
                    data: "",
                },

                codeSMS: {
                    legislation: "",
                    fullName: "",
                    fullAddress: "",
                    fiduciary: "",
                    smsCode: ''
                },
                
                // dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                modalSMS: {
                    show: false,
                    load: false,
                    send: false
                },

                flowSMS: {
                    load: true,
                    smsCodeShow: false,
                    btnResendSMS: false,
                    countDownResendCode: 0,
                    success: false
                }
            }
        },
        
        async mounted(){
            this.showContent = true

            await apiInternational.get('/api/v1/international/compliance/company')
            .then(response => {
                this.dataAccount = response.data
                console.log(this.dataAccount)
                this.showContent = true
            })
            .catch(error => {
                this.errorsGet.dataAccount = error.response.data
                this.showContent = true

                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
            })

            
            // const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            // this.dataCountry = resumeClientCountry.data
        },


        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            signatureName(){
                const nameSignature = this.signature.fullName;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.signature.fullName = nameSignatureFinished;
            },

            listCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        document.getElementById('issuerCountry').focus()
                    }, 10);
                }
            },

            filterListCountry() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("issuerCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },

            validateError(){
                if ( this.signature.fullName.toLowerCase() == this.dataAccount.company.fullName.toLowerCase() ){
                    this.errorsForm.fullName = false
                }

                if ( this.signature.fullAddress != "" ){
                    this.errorsForm.fullAddress = false
                }
            },

            validateForm(){
                this.isEmpty()
                this.saveDigitalSignature()
                
                if ( this.signature.fullName.toLowerCase() != this.dataAccount.company.fullName.toLowerCase() || this.signature.fullName == "" || this.signature.fullName == null ){
                    this.errorsForm.fullName = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.fullName = false
                }

                if ( this.signature.fullAddress == "" || this.signature.fullAddress == null ){
                    this.errorsForm.fullAddress = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.fullAddress = false
                }


                if ( !this.errorsForm.fullName && !this.errorsForm.fullAddress && !this.errorsForm.nationality ){
                    this.auth2fa.status = "auth"
                }
            },

            clear() {
                this.$refs.signature.clear()
            },

            isEmpty() {
                return this.$refs.signature.isEmpty()
            },

            saveDigitalSignature() {
                if (this.isEmpty()) {
                    this.errorsForm.signature = true;
                } else {
                    const svgSignature = this.$refs.signature.save("image/svg+xml");
                    console.log(svgSignature);

                    this.errorsForm.signature = false;
                    this.signature.signature = svgSignature.replace('data:image/svg+xml;base64,', '');
                }

                
                // var _this = this;
                // var svgSignature = _this.$refs.signature.save("image/svg+xml");
                // console.log(svgSignature);
                
                // if ( svgSignature == "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNDkzIDEyMCIgd2lkdGg9IjQ5MyIgaGVpZ2h0PSIxMjAiPjwvc3ZnPg==" ){
                //     this.errorsForm.signature = true
                // } else {
                //     this.errorsForm.signature = false
                //     this.signature.signature = svgSignature.replace('data:image/svg+xml;base64,', '')
                // }

            },

            handleStatusChange(newStatus) {
                this.auth2fa.status = newStatus;
            },

            saveBO(){
                apiInternational.post('api/v1/international/compliance/beneficial_owner', this.signature)
                .then(response => {
                    console.log(response)
                    this.$toast.success(this.getMyLanguage('compliance','compliance.title-success'))

                    setTimeout(() => {
                        window.location.href = '/' + this.flagSelected + '/client-space/purchase'
                    }, 5000);
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error(this.getMyLanguage('box-error','msg-error'))
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            },
            'auth2fa.status'(newStatus) {
                if (newStatus === 'success') {
                    // this.saveBO();
                    console.log("deu certo")
                }
            }
        },

        created () {
            // document.title = this.getMyLanguage("seo","title.account.compliance");
            document.title = "Establishing of the controlling person of operating legal entities and partnerships both not quoted on the stock exchange";
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-beneficial-owner.scss" lang="scss" scoped />