<template>
    <div class="home">
        <div id="dne"></div>
        <Header />

        <div id="clientSpace">
            <section class="client-space">
                <div class="anc" id="client-space"></div>
                <div class="container">
                    <ClientSpaceResume />
                </div>
            </section>

            <a id="anchor-content"></a>

            <div class="load load-200" v-if="!showContent"></div>
            
            <section class="purchase-pay" v-if="showContent">
                
                <div class="container" v-if="blockPurchase">
                    <div class="box-error" v-if="blockPurchase">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","block-purchase") }}</p>
                    </div>
                </div>
                
                <div class="container" v-if="!blockPurchase">

                    <div class="row">
                        <ul class="breadcrumb">
                            <li class="active"><a :href="`/${flagSelected}/client-space/`"><span>{{ getMyLanguage("client-space", "client-space.resume.client-space") }}</span></a></li>
                            <li><a :href="`/${flagSelected}/client-space/purchase`"><span>{{ getMyLanguage("client-space", "client-space.increase-cta") }}</span></a></li>
                        </ul>
                    </div>


                    <!-- NO HASQUOTA -->
                    <div class="row no-has-quota phases" v-if="showContent && (dataTokenSale.quota.available < dataTokenSale.quota.min_amount)">
                        <div class="col-12">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                            
                            <div class="box-success">
                                <div class="quote-limit">
                                    <div>
                                        {{dataTokenSale.quota.used}}/{{dataTokenSale.quota.max_amount}}
                                        DNE BOX
                                    </div>
                                </div>

                                <h3 class="title-secondary">{{ getMyLanguage("box-success", "title-purchase-limit") }}</h3>

                                <div v-if="dataTokenSale.phase.phase_name != 'Default Campaign'" v-html='getMyLanguage("box-success", "purchase-limit")'></div>
                                
                                <div v-else v-html='getMyLanguage("box-success", "purchase-limit-mouth")'></div>
                            </div>                        
                        </div>

                        <Phases v-if="dataTokenSale.phase.phase_name != 'Default Campaign'" />
                    </div>
                    
                    
                    <!-- PHASES -->
                    <div class="row phases" v-if="showContent && $store.state.profile.account_status != 'Verified'">
                        <div class="col-12">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>

                            <h3 class="alert-phase">{{ getMyLanguage("client-space", "client-space.purchase.msg-phases") }}</h3>
                        </div>

                        <Phases />
                    </div>

                    <!-- PAY -->
                    <div class="row pay" v-if="showContent && (dataTokenSale.quota.available >= dataTokenSale.quota.min_amount) && $store.state.profile.account_status === 'Verified'">
                        <div class="col-12">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                        </div>

                        <!-- ORDER SUMMARY -->
                        <div class="col-12 col-lg-5 resume-purchase">
                            <h6>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.title") }}</h6>

                            <!-- <div class="promotion-price">
                                <h3>{{dataTokenSale.phase.phase_name}}</h3>
                                <p>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.quantity-tokens") }}: <strong>{{summary.quantityTokenString}} Tokens</strong></p>
                                <p>
                                    {{ getMyLanguage("client-space", "client-space.purchase.order-summary.promotion-price") }}: 
                                    <strong>{{summary.priceFinalString}} ({{ getMyLanguage("client-space", "client-space.purchase.order-summary.with-discount") }}{{this.dataTokenSale.phase.phase_discount}}{{ getMyLanguage("client-space", "client-space.purchase.order-summary.percent-off") }})
                                        <span v-if="this.dataTokenSale.phase.phase_bonus > 0"> + {{dataTokenSale.phase.phase_bonus}}% {{getMyLanguage("purchase-token","purchase-token.content.phase.bonus")}}</span>
                                    </strong>
                                </p>
                            </div> -->
                            
                            <p>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.original-price") }}: <strong>{{summary.originalPriceString}} / {{summary.quantityTokenString}} Tokens</strong></p>
                            <p v-if="this.dataTokenSale.phase.phase_bonus > 0">{{ getMyLanguage("client-space", "client-space.purchase.order-summary.total") }}: <strong>{{summary.totalTokenString}} DNE Tokens</strong></p>
                            <!-- <p>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.stake") }}: <strong>{{dataTokenSale.phase.phase_stake_months}} {{getMyLanguage("purchase-token","purchase-token.content.phase.months")}}</strong></p> -->


                            <!-- PAYMENT CONDITIONS -->
                            <div class="msg-payment-condition">
                                <div v-for="(listPaymentConditions,index) in dataTokenSale.payment_types" :key="index">
                                    <div v-if='methodPay === listPaymentConditions.paymentTypeCode' v-html='getMyLanguage("client-space",listPaymentConditions.paymentTypeCode + ".text")'></div>
                                </div>
                            </div>

                            <div class="alert-reserve" v-html='getMyLanguage("client-space", "client-space.alert-reserve")'></div>
                        </div>


                        <!-- FORM PAY -->
                        <div class="col-12 col-lg-5 pay-frame">
                            <div class="load" v-if="this.dataPurchase.account_id == null"></div>
                            
                            <div v-if="this.dataPurchase.account_id != null">
                                <div class="row">
                                    <div class="col-12 qtd-package">
                                        <h4>{{ getMyLanguage("client-space", "client-space.purchase.form.select-package") }}</h4>

                                        <div class="quantityBuy">
                                            <img src="@/assets/images/client-space/dne-coin.png" alt="DNE Token">
                                            <div class="quantity">
                                                <div class="tooltip tooltip-less">{{ getMyLanguage("errors", "errors.min") }} {{dataTokenSale.quota.min_amount}} {{ getMyLanguage("errors", "errors.packages") }}</div>
                                                <div class="tooltip tooltip-more">{{ getMyLanguage("errors", "errors.max") }} {{dataTokenSale.quota.available}} {{ getMyLanguage("errors", "errors.packages") }}</div>

                                                <!-- <div class="btnQtd btn-less disable" v-if="disableQuantity === false" @click="this.upSummary(dataPurchase.quantity --)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityLess()" @mouseup="quantityStop()" @mouseout="quantityStop()">-</div>
                                                <div class="form" v-text="dataPurchase.quantity"></div>
                                                <div class="btnQtd btn-more" v-if="disableQuantity === false" @click="this.upSummary(dataPurchase.quantity ++)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityMore()" @mouseup="quantityStop()" @mouseout="quantityStop()">+</div> -->

                                                <div class="btnQtd btn-less disable" @click="this.upSummary(dataPurchase.quantity --)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityLess()" @mouseup="quantityStop()" @mouseout="quantityStop()">-</div>
                                                <div class="form" v-text="dataPurchase.quantity"></div>
                                                <div class="btnQtd btn-more" @click="this.upSummary(dataPurchase.quantity ++)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityMore()" @mouseup="quantityStop()" @mouseout="quantityStop()">+</div>
                                            </div>
                                        </div>

                                        <div class="quantityBuyRange">
                                            <div class="purchased-packages">
                                                {{getMyLanguage("client-space","client-space.purchase.order-summary.purchased-packages")}}
                                                {{dataTokenSale.quota.used}}
                                            </div>
                                            <input type="range" :min="dataTokenSale.quota.min_amount" :max="dataTokenSale.quota.available" step="1" v-model="dataPurchase.quantity" @touchend="upSummary(dataPurchase.quantity)"  @mouseup="upSummary(dataPurchase.quantity)">
                                            <div class="quantity-min-max">
                                                <span class="minRange">{{dataTokenSale.quota.min_amount}}</span>
                                                <span class="maxRange">{{dataTokenSale.quota.available}}</span>
                                            </div>
                                        </div>

                                        <div class="alert-lost-token anime" v-if="(dataPurchase.quantity > (dataTokenSale.quota.available - dataTokenSale.quota.min_amount)) && dataPurchase.quantity != dataTokenSale.quota.available">
                                            <p>
                                                <small>
                                                    <strong>{{dataTokenSale.quota.available - dataPurchase.quantity}} </strong>
                                                    {{getMyLanguage("client-space","client-space.purchase.order-summary.lost-token")}}
                                                </small>
                                            </p>
                                        </div>

                                        <div class="summary-mobile">
                                            <table class="summary-mobile-cont">
                                                <tr>
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.quantity-tokens") }}:</small></p></td>
                                                    <td class="text-right"><strong class="asset-dne">{{summary.quantityTokenString}}</strong></td>
                                                </tr>
                                                
                                                <!-- <tr>
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.token-bonus-stake") }}:</small></p></td>
                                                    <td class="text-right"><strong class="asset-dne">{{dataPurchase.quantity * dataTokenSale.phase.purchase_bonus}}</strong></td>
                                                </tr> -->
                                                
                                                <tr class="calculated-value promotion-price" v-if="dataTokenSale.phase.phase_bonus > 0">
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.token-bonus-stake") }}:</small></p></td>
                                                    <td class="text-right">
                                                        <strong>
                                                            <span class="asset-dne">+ {{dataPurchase.quantity * dataTokenSale.phase.purchase_bonus}}</span>
                                                            <small>({{ getMyLanguage("client-space","client-space.purchase.order-summary.total") }}:&nbsp;{{((dataPurchase.quantity * dataTokenSale.phase.purchase_bonus) + summary.quantityToken)}}&nbsp;{{ getMyLanguage("client-space","client-space.purchase.order-summary.tokens") }})</small>
                                                        </strong>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.original-price") }}:</small></p></td>
                                                    <td class="text-right"><strong>{{summary.originalPriceString}}</strong></td>
                                                </tr>
                                                
                                                <!-- <tr class="promotion-price">
                                                    <td><p><small>{{ getMyLanguage("client-space", "client-space.purchase.order-summary.promotion-price") }}:</small></p></td>
                                                    <td class="text-right">
                                                        <strong>
                                                            <span>{{summary.priceFinalString}}</span>
                                                            <small>({{ getMyLanguage("client-space", "client-space.purchase.order-summary.with-discount") }}{{this.dataTokenSale.phase.phase_discount}}{{ getMyLanguage("client-space", "client-space.purchase.order-summary.percent-off") }})</small>
                                                        </strong>
                                                    </td>
                                                </tr> -->
                                                
                                                <tr v-if='methodPay === "crypto"' class="calculated-value">
                                                    <td class="no-border"><p class="value-ticker"><small>TETHER USDT:</small></p></td>
                                                    <td class="no-border text-right">
                                                        <strong>
                                                            <span class="tether">{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}}</span>
                                                            <small>({{summary.priceFinalString}} x {{ticker.toLocaleString("pt-PT", {style:"currency", currency:"USD"})}})</small>
                                                        </strong>
                                                    </td>
                                                </tr>

                                                <tr v-if='methodPay === "swapix"' class="calculated-value">
                                                    <td class="no-border"><p class="value-ticker"><small>SWAPIX USDT:</small></p></td>
                                                    <td class="no-border text-right">
                                                        <strong>
                                                            <span>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}}</span>
                                                            <small>({{summary.priceFinalString}} x {{tickerPix.toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}})</small>
                                                        </strong>
                                                    </td>
                                                </tr>

                                                <tr v-if='methodPay === "crypto" || methodPay === "swapix"' class="quote-updates">
                                                    <td colspan="2">
                                                        <em>{{getMyLanguage("client-space","quote-updates")}}</em>
                                                        <strong v-if="quoteTimer >= 60"> {{quoteTimerMinutes}} <span v-if="quoteTimerMinutes > 1">{{getMyLanguage("client-space","quote-updates.minutes")}}</span><span v-if="quoteTimerMinutes == 1">{{getMyLanguage("client-space","quote-updates.minute")}}</span></strong>
                                                        <strong v-if="quoteTimer < 60"> {{quoteTimer}} {{getMyLanguage("client-space","quote-updates.seconds")}}</strong>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>


                                    <!-- SELECT PAYMENT TYPE -->
                                    <div class="col-12 select-payment-method" v-if="dataTokenSale.payment_types.length > 1">
                                        <h4>{{ getMyLanguage("client-space", "client-space.purchase.form.select-payment-method") }}</h4>
                                        <ul>
                                            <li v-for="(listPaymentType,index) in dataTokenSale.payment_types" :key="index" :class="[{hide: !listPaymentType.enabled},listPaymentType.paymentTypeCode]">
                                                <div class="select-payment-type" v-if="listPaymentType.enabled" :class='{active: methodPay === listPaymentType.paymentTypeCode}' @click='methodPay = listPaymentType.paymentTypeCode, ticker = listPaymentType.ticker, dataPurchase.paymentTypeId = listPaymentType.paymentTypeId'>
                                                    <span v-if='listPaymentType.paymentTypeCode != "credit_card"'>{{listPaymentType.name}}</span>
                                                    <span v-if='listPaymentType.paymentTypeCode === "credit_card"'>{{ getMyLanguage("client-space","Credit Card") }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>



                                <!-- CREDIT CARD -->
                                <div class="row method-pay-animation" v-if='methodPay === "credit_card"'>
                                    <div class="col-12">
                                        <div class="alert-card" v-html='getMyLanguage("client-space","client-space.purchase.form.credit-card.warning")'></div>
                                    </div>
                                    <div class="col-12">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-cardHolder") }}
                                            <input type="text" class="form" :class="{error: validateCard.cardHolder == false}" id="name-card" v-model="dataPurchase.cardHolder" required>
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-cardNumber") }}
                                            <input type="text" class="form" :class="{error: validateCard.cardNumber == false}" id="card-number" v-mask="'0000 0000 0000 0000'" placeholder="____ ____ ____ ____" v-model="dataPurchase.cardNumber" required>
                                        </label>
                                    </div>
                                    <div class="col-7">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-expiry") }}
                                            <input type="text" class="form" :class="{error: validateCard.expiry == false}" id="card-expiry" v-mask="'00/00'" placeholder="__/__" v-model="dataPurchase.expiry" required>
                                        </label>
                                    </div>
                                    <div class="col-5">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.purchase.form.lbl-cvv") }}
                                            <input type="text" class="form" :class="{error: validateCard.cvv == false}" id="card-cvv" v-mask="'000'" placeholder="___" v-model="dataPurchase.cvv" required>
                                        </label>
                                    </div>
                                </div>


                                <!-- CHECKS -->
                                <div class="checks-lnkTerms">
                                    <div class="checks-terms">

                                        <!-- CHECKS SWAPIX -->
                                        <div class="checks-suapix" v-if='methodPay === "swapix"'>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck01" class="form-check" v-model="accepts.swapix01">
                                                <label for="swapixCheck01" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix01") }}</small></label>
                                            </div>

                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck02" class="form-check" v-model="accepts.swapix02">
                                                <label for="swapixCheck02" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix02") }}</small></label>
                                            </div>
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck03" class="form-check" v-model="accepts.swapix03">
                                                <label for="swapixCheck03" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix03") }}</small></label>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptConditions" class="form-check" v-model="accepts.Conditions">
                                            <label for="acceptConditions" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-reserve.accept") }}</small></label>
                                        </div>
                                        
                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptTerms" class="form-check" v-model="accepts.Terms">
                                            <label for="acceptTerms" class="form-check-label">
                                                <small>
                                                    {{ getMyLanguage("client-space", "client-space.alert-reserve.accept-terms") }}
                                                    <a :href='"/" + flagSelected + "/terms-and-conditions"' target="_blank" v-html='getMyLanguage("client-space", "client-space.alert-reserve.accept-terms.lnk")'></a>
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- BOX ERROR -->
                            <!-- <div class="col-12">
                                <div class="box-error" v-if="showContent && errorsPost.dataPurchase != false">
                                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                    <p v-if='errorsPost.dataPurchase != ("invalid_request_error" && "third_party_stripe_create_payment_intent_failure")'>{{ getMyLanguage("box-error","msg-error") }}</p>
                                    <p v-if='errorsPost.dataPurchase === "third_party_stripe_create_payment_intent_failure"'>{{ getMyLanguage("errors","errors.third_party_stripe_create_payment_intent_failure") }}</p>
                                    <p v-if='errorsPost.dataPurchase === "invalid_request_error"'>{{ getMyLanguage("errors","errors.third_party_stripe_create_account_failure") }}</p>
                                    <div class="cod-error">
                                        Cód.: {{errorsPost.dataPurchase}}
                                    </div>
                                </div>
                            </div> -->

                            <!-- OPEN MODAL CONFIRM -->
                            <div v-if='methodPay != "swapix"'>
                                <input type="hidden" v-model="dataPurchase.phaseProductId">

                                <div class="btn-primary disabled" v-if='(methodPay != "crypto" && methodPay != "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{summary.priceFinalString}}</small></div>
                                <div @click="openModalConfirm()" expand="block" class="btn-primary" v-if='(methodPay != "crypto" && methodPay != "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{summary.priceFinalString}}</small></div>

                                <div class="btn-primary btn-pay-crypto disabled" v-if='(methodPay === "crypto" || methodPay === "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='(methodPay === "crypto" || methodPay === "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                            </div>
                            
                            <div v-if='methodPay === "swapix"'>
                                <div class="btn-primary btn-pay-crypto disabled" v-if='accepts.swapix01 != true || accepts.swapix02 != true || accepts.swapix03 != true || accepts.Conditions != true || accepts.Terms != true'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                                <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='accepts.swapix01 && accepts.swapix02 && accepts.swapix03 && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("client-space", "client-space.purchase.form.lbl-pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- MODAL CONFIRM -->
            <div class="modal modal-confirm" v-if="modalConfirm">
                <div class="modal-cont">
                    <div class="modal-close" @click="modalConfirm = false"></div>
                    
                    <div class="confirm-purchase"  v-if="showContent">
                        <h2 class="title-secondary">{{getMyLanguage("client-space","modal-confirm.title")}}</h2>
                        
                        <div class="form-group-check" v-if='methodPay != "crypto" && methodPay != "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{summary.priceFinalString}}</strong></small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "crypto"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} TETHER USDT</strong>&nbsp;({{summary.priceFinalString}})</small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</strong></small></label>
                        </div>
                        
                        <div class="btn-primary disabled" v-if="!accepts.Confirm">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>
                        <div @click="purchase()" class="btn-primary" v-if="accepts.Confirm && !load">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>                        
                        <div class="btn-primary load" v-if="load"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import Phases from '@/components/Phases.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import {loadStripe} from '@stripe/stripe-js';
    const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PK);
    let cancelThreeDS = ""
    
    import apiInternational from '@/config/apiInternational.js'

    import moment from "moment";

    import { handleError } from '@/services/errorHandler.js'

    export default {
        components: {
            Header,
            ClientSpaceResume,
            Phases,
        },
        
        beforeMount(){
            this.loginStatus()
        },

        data() {
            return {
                load: false,
                showContent: false,
                modalConfirm: false,
                flagSelected: "en",

                phaseID: "",
                urlGetPhase: "",

                dataTokenSale: [],
                dataSummary: {},
                dataPurchase: {},
                disableQuantity: false,

                validateCard: {
                    cardHolder: null,
                    cardNumber: null,
                    expiry: null,
                    cvv: null
                },

                scaLink: "",
                redirectTo: "",

                methodPay: "sepa_swift",
                ticker: '',
                tickerPix: '',
                quoteTimer: 0,
                quoteTimerMinutes: 0,

                errorsForm: {
                    fullName: null
                },

                signature: {
                    fullName: "",
                    date: ""
                },

                accepts: {
                    Conditions: false,
                    Terms: false,
                    Confirm: false
                },

                summary: {},
                blockPurchase: false,
            }
        },

        async mounted(){
            const urlParamsPhase = new URLSearchParams(window.location.search);
            this.phaseID = urlParamsPhase.get('phase-id')

            if ( this.phaseID ){
                this.urlGetPhase = `/api/v2/ico/token_sale/purchase/${this.phaseID}`
            } else {
                this.urlGetPhase = "/api/v2/ico/token_sale/purchase/current/"
            }
            
            // await apiInternational.get('/api/v2/ico/token_sale/purchase/current/')
            await apiInternational.get(this.urlGetPhase)
             .then(response => {
                this.dataTokenSale = response.data

                setTimeout(() => {
                    this.showContent = true
                    this.showContent = true
                    this.load = false
                }, 500);

                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = false

                    this.ticker = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                    this.tickerPix = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                }, 1000);
             })
            .catch(error => {
                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
            })
    
            
            this.dataPurchase.phaseId = this.dataTokenSale.phase.phase_id
            this.dataPurchase.quantity = this.dataTokenSale.quota.min_amount
            this.dataPurchase.account_id = this.$store.state.profile.account_id
            this.dataPurchase.paymentTypeId = "732e682f-a99f-4914-b2b4-c56351b12ca6"

            this.dataPurchase.cardHolder = ""
            this.dataPurchase.cardNumber = ""
            this.dataPurchase.expiry = ""
            this.dataPurchase.cvv = ""
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);
            setTimeout(() => {
                this.dataPurchase.account_id = this.$store.state.profile.account_id
                this.showContent = true
            }, 3000);
            setTimeout(() => {
                this.dataPurchase.account_id = this.$store.state.profile.account_id
                this.showContent = true
            }, 5000);
            setTimeout(() => {
                this.dataPurchase.account_id = this.$store.state.profile.account_id
                this.showContent = true
            }, 10000);


            // const urlParams = new URLSearchParams(window.location.search);
            // const phasePurchaseIdParams = urlParams.get('phasePurchaseId');
            // const quantityParams = urlParams.get('quantity');

            // if ( urlParams != '' ){
            //     this.dataPurchase.phasePurchaseId = phasePurchaseIdParams
            //     this.dataPurchase.quantity = quantityParams
            //     this.disableQuantity = true
            // }
            
            this.upSummary()
            

            setTimeout(() => {
                $('.products-list .form-group-check:nth-of-type(1) label').trigger('click');
            }, 100);

            this.quoteTimer = 130
            this.countDownQuote();
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.purchase");
                document.head.querySelector('meta[name=description]').content = ''
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            changeCurrent(){
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = false

                    this.ticker = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                    this.tickerPix = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                }, 1000);
            },

            upSummary(){
                let price = this.dataTokenSale.phase.product_price
                let quantityTokens = this.dataTokenSale.phase.product_quantity
                let bonus = this.dataTokenSale.phase.phase_bonus
                let discount = this.dataTokenSale.phase.phase_discount
                let min = this.dataTokenSale.quota.min_amount
                let max = this.dataTokenSale.quota.available

                $('.btnQtd.btn-less').removeClass('disable')
                $('.btnQtd.btn-more').removeClass('disable')

                if ( this.dataPurchase.quantity <= min ){
                    this.dataPurchase.quantity = min
                    this.dataPurchase.quantity = min

                    $('.btnQtd.btn-less').addClass('disable')
                    $('.quantityBuy .tooltip-less').fadeToggle();
                }
                
                if ( this.dataPurchase.quantity >= max){
                    this.dataPurchase.quantity = max
                    this.dataPurchase.quantity = max

                    $('.btnQtd.btn-more').addClass('disable')
                    $('.quantityBuy .tooltip-more').fadeToggle();
                }
                
                setTimeout(() => {
                    $('.quantityBuy .tooltip').hide()
                }, 2000);


                this.summary.originalPrice = price * this.dataPurchase.quantity
                this.summary.quantityToken = quantityTokens * this.dataPurchase.quantity
                this.summary.priceFinal = (price * this.dataPurchase.quantity)-((price * this.dataPurchase.quantity)*(discount / 100))
                this.summary.totalToken = (this.dataPurchase.quantity * quantityTokens) * (1 + (bonus / 100));
                this.summary.priceFinalCrypto = (this.summary.priceFinal * this.ticker)
              
                this.quantityPurchase()
            },


            quantityLess(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity -= 1)
                    }, 200);
                }, 150)
            },

            quantityMore(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity += 1)
                    }, 200);
                }, 150)
            },

            quantityStop(){
                clearInterval(this.interval)
            },

            purchase(){
                this.load = true
                const purchase = this.dataPurchase;

                apiInternational.post("/api/v1/ico/token_sale/purchase/", purchase)
                .then(response => {
                    if ( response.data.success ){
                        window.location.href = '/' + response.data.returnUrl

                    } else if ( !response.data.success && response.data.status === "requires_action" && response.data.nextAction.type === "use_stripe_sdk" ){
                        
                        stripePromise.then(responseStripe => {
                            responseStripe.confirmCardPayment(response.data.nextAction.clientSecret)
                            .then(result => {
                                if ( result.error ){
                                    cancelThreeDS = result.error.type
                                    console.log(cancelThreeDS)
                                    window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=${result.error.type}`
                                    
                                } else if ( result.paymentIntent.status === "succeeded" ){
                                    window.location.href = `/${response.data.returnUrl}`
                                }
                            })
                        })
                        .catch(error => {
                            window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=${error}`
                        })
                    } else {
                        window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=error_3ds`
                    }
                })
                .catch(error => {
                    window.location.href = `/${this.flagSelected}/client-space/purchase-error?error=${error.response.data[0]}`
                })
            },

            checkPurchaseCreditCard(){
                window.location.href = `/${this.redirectTo}`
            },

            quantityPurchase() {
                let qtdTokens = this.summary.quantityToken
                let totalTokens = this.summary.totalToken
                let priceOriginal = this.summary.originalPrice
                let priceFinal = this.summary.priceFinal
                let priceFinalCrypto = this.summary.priceFinalCrypto
                
                qtdTokens = qtdTokens.toLocaleString("pt-PT")
                totalTokens = totalTokens.toLocaleString("pt-PT")
                priceOriginal = priceOriginal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                priceFinal = priceFinal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                priceFinalCrypto = priceFinalCrypto.toLocaleString("pt-PT")
                
                this.summary.quantityTokenString = qtdTokens
                this.summary.totalTokenString = totalTokens
                this.summary.originalPriceString = priceOriginal
                this.summary.priceFinalString = priceFinal
                this.summary.priceFinalCryptoString = priceFinalCrypto
            },

            countDownQuote() {                
                if(this.quoteTimer > 0) {
                    setTimeout(() => {
                        this.quoteTimer -= 1

                        if ( this.quoteTimer >= 60 ){
                            this.quoteTimerMinutes = this.quoteTimer / 60
                            this.quoteTimerMinutes = String(this.quoteTimerMinutes).split(".")[0]
                        }

                        this.countDownQuote()
                    }, 1000)
                } else{
                    this.changeCurrent()
                    this.dataTokenSale.payment_types[2].ticker = this.ticker
                    this.upSummary()
                    this.quoteTimer = 130
                    this.countDownQuote()
                }
            },

            openModalConfirm(){
                if ( this.methodPay === "credit_card" ){
                    if ( this.dataPurchase.cardHolder.length < 5 || this.dataPurchase.cardHolder == null ){ this.validateCard.cardHolder = false } else { this.validateCard.cardHolder = true }
                    if ( this.dataPurchase.cardNumber.length < 16 || this.dataPurchase.cardNumber == null ){ this.validateCard.cardNumber = false } else { this.validateCard.cardNumber = true }
                    if ( this.dataPurchase.expiry.length < 2 || this.dataPurchase.expiry == null ){ this.validateCard.expiry = false } else { this.validateCard.expiry = true }
                    if ( this.dataPurchase.cvv.length < 3 || this.dataPurchase.cvv == null ){ this.validateCard.cvv = false } else { this.validateCard.cvv = true }

                    if ( this.validateCard.cardHolder == true && this.validateCard.cardNumber == true && this.validateCard.expiry == true && this.validateCard.cvv == true ){
                        this.validateCard .cardHolder = true
                        this.modalConfirm = true
                    }
                } else {
                    this.modalConfirm = true
                    // this.errorsPost.dataPurchase = false
                }
            },

            dateTimeSignature(value) {
                return moment(value).format('ll');
            },

            scrollContract() {
                const contractDiv = this.$refs.contract;
                contractDiv.scrollTop = contractDiv.scrollHeight;
            },

            signatureName(){
                const nameSignature = this.signature.fullName;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.signature.fullName = nameSignatureFinished;
            },

            validateError(){
                if ( this.signature.fullName.toLowerCase() == this.$store.state.profile.profile_full_name.toLowerCase() ){
                    this.errorsForm.fullName = "validated"
                } else {
                    this.errorsForm.fullName = true
                }

                if ( this.signature.fullAddress != "" ){
                    this.errorsForm.fullAddress = false
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.client-space.purchase");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpacePurchase.scss" lang="scss" scoped />

<style scoped>
    .alert-lost-token{animation: lostToken .4s alternate;}

    @keyframes lostToken{
        0% {top: 20px;}
        100% {top: 0;}
    }
</style>